import './Shop.css'

function Shop(){
    return(
        <div className='shop'>
            <h1 className='shop_heading'>SHOP</h1>
            <div className='categories'>
                <a href='/socks'><span className='first_c'>SOCKS</span></a>
                <a href='/shorts'><span className='second_c'>SHORTS</span></a>
                <a href='/stickers'><span className='third_c'>STICKERS</span></a>
            </div>
        </div>
    )
}

export default Shop;