import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ShortsDetailedPage.css';

function ShortDetailsPage({ shorts, addToCart }) {
    const { no } = useParams();
    const navigate = useNavigate();
    const short = shorts.find(short => short.no === Number(no));
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = () => {
        addToCart(short, quantity); // This should update the cart in your parent component
        navigate('/cart');
    };


    return (
        <div className='info'>
            <div className='photo_and_p'>
                <img src={short.img} alt={short.heading}/>
                <p className='shorts_paragraph'>{short.description}</p>
            </div>

            <div className='info_content'>
                <h1 className='h1_heading'>{short.heading}</h1>
                <p className='price_p'>Price: ${short.price}</p>
                <div className='qnt'>
                    <h1 className='pcs'>Quantity: </h1>
                    <input type='number' value={quantity} onChange={e => setQuantity(e.target.value)} min='1'/>
                </div>
                <button className='add_to_cart' onClick={handleAddToCart}>Add to Cart</button>
            </div>
        </div>
    );
}

export default ShortDetailsPage;
