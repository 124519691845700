import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './SockCard.css'

function SockCard(props){
    return(
        <div className='sock_container'>
            <Link to={`/socks/${props.no}`}>
                <img src={props.img}/>
            </Link>
            <h1 className='sock_heading'>{props.heading}</h1>
            <span className='black_line'></span>
            <p className='price'>${props.price}</p>
        </div>
    )
}

export default SockCard