import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './StickerCard.css';

function StickerCard(props) {
    return (
        <div className='sticker_container'>
            <Link to={`/stickers/${props.no}`}>
                <img src={props.img} alt={props.heading}/>
            </Link>
            <h1 className='sticker_heading'>{props.heading}</h1>
            <span className='black_line'></span>
            <p className='price'>${props.price}</p>
        </div>
    );
}


export default StickerCard;
