import './Socks.css'
import SockCard from "./SockCard/SockCard";
import img1 from '../images/socks/1.png'
import img2 from '../images/socks/2.png'
import img3 from '../images/socks/3.png'
import img4 from '../images/socks/4.png'
import img5 from '../images/socks/5.png'
import img6 from '../images/socks/6.png'
import img7 from '../images/socks/7.png'
function Socks({socks}){

    return(
        <div className='socks'>
            <h1 className='socks_heading'>SOCKS</h1>
            <div className='socks_list'>
                {socks.map(sock =>
                    <SockCard
                        key={sock.no}
                        no={sock.no} // Add this line
                        img={sock.img}
                        heading={sock.heading}
                        price={sock.price}
                        description={sock.description}
                    />
                )}

            </div>
        </div>
    )
}

export default Socks;