import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import './SocksDetailedPage.css'

function SocksDetailsPage({ socks, addToCart }) {
    const { no } = useParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const sock = socks.find(sock => sock.no === Number(no));
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = () => {
        addToCart(sock, quantity); // This should update the cart in your parent component
        navigate('/cart');
    };





    return (
        <div className='info'>
            <div className='photo_and_p'>
                <img src={sock.img} alt={sock.heading}/>
                <p className='socks_paragraph'>{sock.description}</p>
            </div>

            <div className='info_content'>
                <h1 className='h1_heading'>{sock.heading}</h1>
                <p className='price_p'>Price: ${sock.price}</p>
                <div className='qnt'>
                    <h1 className='pcs'>Quantity: </h1>
                    <input type='number' value={quantity} onChange={e => setQuantity(e.target.value)} min='1'/>

                </div>
                <button className='add_to_cart' onClick={handleAddToCart}>Add to Cart</button>
            </div>


        </div>
    );
}

export default SocksDetailsPage;
