import StickerCard from "./StickerCard/StickerCard";
import './Stickers.css'
function Stickers({ stickers }) {
    return (
        <div className="stickers">
            <h1 className="stickers_heading">STICKERS</h1>
            <div className="stickers_list">
                {stickers.map(sticker => (
                    <StickerCard
                        key={sticker.no} // Use sticker.no as the key
                        no={sticker.no} // Pass the no property to StickerCard
                        img={sticker.img}
                        heading={sticker.heading}
                        price={sticker.price}
                        description={sticker.description}
                    />
                ))}
            </div>
        </div>
    );
}

export default Stickers;