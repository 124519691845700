import { Link } from 'react-router-dom';
import './ShortCard.css';

function ShortCard(props) {
    return (
        <div className='short_container'>
            <Link to={`/shorts/${props.no}`}>
                <img src={props.img} alt={props.heading}/>
            </Link>
            <h1 className='short_heading'>{props.heading}</h1>
            <span className='black_line'></span>
            <p className='price'>${props.price}</p>
        </div>
    );
}

export default ShortCard;
