import './About.css'

function About(){
    return (
        <div className='about_page'>
            <h1 className='about_text'>Lunchsox is on a mission to
                fight hunger by selling
                awesome socks. Lunchsox is
                a family farm located in <a className='link_location' href='https://www.google.com/maps/place/Madrid,+IA+50156,+USA/@41.8760585,-93.8328507,15z/data=!3m1!4b1!4m6!3m5!1s0x87edd49f877d48d9:0x4d59d5a6ae6a35fb!8m2!3d41.8779511!4d-93.8156689!16zL20vMHNyeTc?entry=ttu'>Lowa,
                    United States</a>. The Lunchsox
                website is powered by <a className='link_site_1' href='https://socialmarketing90.com/'>Socialmarketing90's </a>
                technology and <a className='link_site_2' href='https://vercel.com/'>Vercel hosting.</a> </h1>
    </div>
    )
}
export default About;
