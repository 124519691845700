import './Mission.css'
import instagram from '../images/instagram.png'
function Mission() {
    return (
        <div className='mission'>
            <h1 className='mission_heading'>our mission</h1>
            <p className='mission_p'>Create locally grown socks that love people and the environment,<br/>while using business to sock hunger and promote a kinder world.</p>
            <a href='/shop' className='shop_now'>shop now</a>
            <a href='https://www.instagram.com/lunchsox_insta/'><img src={instagram} className='instagram'/></a>
        </div>
    )
}

export default Mission;