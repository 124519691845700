import React, { useEffect, useState } from 'react';
import './CartPage.css';

function CartPage() {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        // Load cart items from localStorage when the component mounts
        const loadedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(loadedCartItems);
    }, []);

    const removeFromCart = (itemToRemove) => {
        const updatedCart = cartItems.filter(item => item !== itemToRemove);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setCartItems(updatedCart); // Update cartItems in the parent component
    };

    const calculateTotal = () => {
        let total = 0;
        cartItems.forEach(item => {
            if (item.item && item.item.price) { // Check if item.item and its properties are defined
                total += item.item.price * item.quantity;
            }
        });
        return total.toFixed(2);
    };

    return (
        <div className="cart-container">
            <h1>Your Cart</h1>
            {cartItems.length === 0 ? (
                <p className='empty'>Your cart is empty</p>
            ) : (
                <div className="cart-items">
                    {cartItems.map(item => (
                        <div key={item.item && item.item.no} className="cart-item">
                            {item.item && ( // Check if item.item is defined
                                <>
                                    <img src={item.item.img} alt={item.item.heading} className="cart-item-image" />
                                    <div className="cart-item-details">
                                        <h2>{item.item.heading}</h2>
                                        <p>Quantity: {item.quantity}</p>
                                        <p>Price: ${item.item.price}</p>
                                        <p>Total: ${(item.item.price * item.quantity).toFixed(2)}</p>
                                        <button className='remove_button' onClick={() => removeFromCart(item)}>Remove</button>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    <div className="cart-summary">
                        <h2>Order Summary</h2>
                        <p>Total Items: {cartItems.length}</p>
                        <p>Total Price: ${calculateTotal()}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CartPage;
