import './Footer.css'
import logo from '../images/Lunchsox logo.png'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
function Footer(){
    return (
        <div className='footer'>
            <div className='line'></div>
            <div className='content'>
                <div className='column_1'>
                    <h3 className='customer_heading'>CUSTOMER CARE</h3>
                    <a href='' className='customer_a'>Contact Us ></a>
                    <a href='/about' className='customer_a'>About Us ></a>
                </div>
                <a href='/'><img src={logo} className='logo_footer'/></a>
                <div className='column_2'>
                    <h3 className='customer_heading'>STAY CONNECTED</h3>
                    <div className='logos'>
                        <a href='https://www.facebook.com/lunchsox/' className='icon_footer'><img src={facebook}/></a>
                        <a href='https://www.instagram.com/lunchsox_insta/' className='icon_footer'><img src={instagram}/></a>
                    </div>
                </div>
            </div>
            <span className='line'></span>
        </div>
    )
}

export default Footer;