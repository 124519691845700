import './Shorts.css';
import ShortCard from './ShortCard/ShortCard';


function Shorts({ shorts }) {
    return (
        <div className='shorts'>
            <h1 className='shorts_heading'>SHORTS</h1>
            <div className='shorts_list'>
                {shorts.map(short =>
                    <ShortCard
                        key={short.no}
                        no={short.no}
                        img={short.img}
                        heading={short.heading}
                        price={short.price}
                        description={short.description}
                    />
                )}
            </div>
        </div>
    );
}

export default Shorts;
