import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header/Header";
import Mission from "./Mission/Mission";
import Footer from "./Footer/Footer";
import Shop from "./Shop/Shop";
import Socks from "./Socks/Socks";
import SocksDetailsPage from "./Socks/SocksDetailedPage/SocksDetailedPage";
import CartPage from "./CartPage/CartPage";


import img1 from "./images/socks/1.png";
import img2 from "./images/socks/2.png";
import img3 from "./images/socks/3.png";
import img4 from "./images/socks/4.png";
import img5 from "./images/socks/5.png";
import img6 from "./images/socks/6.png";
import img7 from "./images/socks/7.png";

import shorts1 from './images/socks/shorts1.png';
import shorts2 from './images/socks/shorts2.png';
import shorts3 from './images/socks/shorts3.png';
import shorts4 from './images/socks/shorts4.png';

import sticker from './images/socks/sticker.jpg'

import Shorts from "./Shorts/Shorts";
import ShortsDetailedPage from "./Shorts/ShortsDetailedPage/ShortsDetailedPage";
import Stickers from "./Stickers/Stickers";
import StickerDetailsPage from './Stickers/StickerDetailedPage/StickerDetailedPage'
import About from "./About/About";

const socks = [
    {no:1, img:img1, heading:'The Myrtle', price:'25', description:'These are premium socks priced at $25. They are known for their unique design and comfort.'},
    {no:2, img:img2, heading:'Alpaca Socks: Marled Twist', price:'18', description:'Priced at $18, these socks are made from Alpaca wool, known for its softness and warmth. The marled twist design adds a touch of elegance.'},
    {no:3, img:img3, heading:'Soup Socks: Monk Black Top', price:'18', description:'These $18 socks have a distinctive monk black top design. They are perfect for those who prefer a bit of style with their comfort.'},
    {no:4, img:img4, heading:'Friday Socks', price:'23', description:'Celebrate the end of the week with these $23 Friday Socks. Their unique design is sure to bring a smile to your face.'},
    {no:5, img:img5, heading:'Alpaca Socks: Black w/ Scarlet', price:'21', description:'These Alpaca wool socks come in a striking black color with scarlet details. Priced at $21, they offer both style and comfort.'},
    {no:6, img:img6, heading:'Alpaca Socks: Black w/ Cresting Wave', price:'18', description:'These $18 Alpaca socks feature a unique black design with a cresting wave pattern. They are perfect for those who want to make a statement with their footwear.'},
    {no:7, img:img7, heading:'Soup Socks: Monk Cream Top TB', price:'25', description:'Priced at $25, these socks feature a monk cream top design. They are a great choice for those who value comfort and style.'}
];

const shorts = [
    {
        no: 1,
        img: shorts1,
        heading: 'Classic Denim Shorts',
        price: '39.99',
        description: 'Classic denim shorts featuring a distressed look and frayed hems. Perfect for casual outings and summer days.'
    },
    {
        no: 2,
        img: shorts2,
        heading: 'Cargo Utility Shorts',
        price: '45.50',
        description: 'Cargo utility shorts with multiple pockets for practical storage. Made from durable cotton fabric for outdoor activities.'
    },
    {
        no: 3,
        img: shorts3,
        heading: 'Athletic Performance Shorts',
        price: '29.99',
        description: 'Athletic performance shorts engineered for maximum mobility and breathability. Ideal for workouts and sports.'
    },
    {
        no: 4,
        img: shorts4,
        heading: 'Patterned Board Shorts',
        price: '27.95',
        description: 'Colorful patterned board shorts perfect for beach days and water sports. Quick-drying and comfortable.'
    }
];

const stickers = [
    {
        no: 1,
        img: sticker,
        heading: 'Sheep Die-cut Sticker',
        price: '2.50',
        description: 'Beautify your laptop or other surface with this adorable Lunchsox sheep sticker. Your sticker will tell all your friends that you love wearing Lunchsox AND that you are a hunger fighter.\n' +
            '\n' +
            'Sticker size: 3" x 2.5"'
    }
];

function App() {
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(storedCart);
    }, []); // Run once on component mount to retrieve cart from localStorage

    const addToCart = (item, quantity) => {
        setCart(prevCart => [...prevCart, { item, quantity }]);
        localStorage.setItem('cart', JSON.stringify([...cart, { item, quantity }]));
    };

    const removeFromCart = (itemToRemove) => {
        const updatedCart = cart.filter(item => item.item.no !== itemToRemove.item.no);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    return (
        <BrowserRouter>
            <>
                <Header />
                <Routes>
                    <Route path="/" element={<Mission />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/about" element={<><About/></>} />

                    <Route path="/socks" element={<Socks socks={socks} />} />
                    <Route path="/socks/:no" element={<SocksDetailsPage socks={socks} addToCart={addToCart} />} />

                    <Route path="/shorts" element={<Shorts shorts={shorts} />} />
                    <Route path="/shorts/:no" element={<ShortsDetailedPage shorts={shorts} addToCart={addToCart} />} />

                    <Route path="/stickers" element={<Stickers stickers={stickers} />} />
                    <Route path="/stickers/:no" element={<StickerDetailsPage stickers={stickers} addToCart={addToCart} />} />

                    <Route path="/cart" element={<CartPage cartItems={cart} setCartItems={setCart} />} />

                </Routes>
                <Footer />
            </>
        </BrowserRouter>
    );
}

export default App;
