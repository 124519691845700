import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './StickerDetailedPage.css';

function StickerDetailsPage({ stickers, addToCart }) {
    const { no } = useParams();
    const navigate = useNavigate();
    const sticker = stickers.find(sticker => sticker.no === Number(no));
    const [quantity, setQuantity] = useState(1);

    const handleAddToCart = () => {
        addToCart(sticker, quantity);
        navigate('/cart');
    };


    return (
        <div className="info">
            <div className="photo_and_p">
                <img src={sticker.img} alt={sticker.heading}/>
                <p className='stickers_paragraph'>{sticker.description}</p> {}
            </div>
            <div className="info_content">
                <h1 className='h1_heading'>{sticker.heading}</h1>
                <p className='price_p'>Price: ${sticker.price}</p>
                <div className="qnt">
                    <label className='pcs'>Quantity:</label>
                    <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} min="1" />
                </div>
                <button className='add_to_cart' onClick={handleAddToCart}>Add to Cart</button>
            </div>
        </div>
    );
}

export default StickerDetailsPage;
